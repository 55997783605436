// extracted by mini-css-extract-plugin
export var column = "ProvidersDashboardOptions__column__aGwZL";
export var flex = "ProvidersDashboardOptions__flex__UoxsB";
export var flexColumn = "ProvidersDashboardOptions__flexColumn__ugh74";
export var gap1 = "ProvidersDashboardOptions__gap1__NYY5Q";
export var gap2 = "ProvidersDashboardOptions__gap2__wxZqM";
export var gap3 = "ProvidersDashboardOptions__gap3__DSPTt";
export var gap4 = "ProvidersDashboardOptions__gap4__R1s9z";
export var gap5 = "ProvidersDashboardOptions__gap5__ZP7y9";
export var popoverBody = "ProvidersDashboardOptions__popoverBody__C9isx";
export var popoverBodyLink = "ProvidersDashboardOptions__popoverBodyLink__PxDCP";
export var popoverBodyLinkIcon = "ProvidersDashboardOptions__popoverBodyLinkIcon__Nslnz";
export var row = "ProvidersDashboardOptions__row__0f5tI";